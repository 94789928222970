$(function() {
    $('.btn-toggle-menu').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.toggle-menu').slideToggle();
    });

    $('.filters-titles').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.filter-navigation').slideToggle();
    });
});



$(function() {
    var $parentMenu = $('.product-categories li.cat-parent');
    
    if(!$parentMenu.length) return

    var $asideSubmenuActive = $('.children li.current-cat-parent');

    $asideSubmenuActive.parent().addClass('show');
    $asideSubmenuActive.parent().slideToggle(350);
    $asideSubmenuActive.closest('.current-cat-parent').addClass('active');

    if(document.querySelector('.current-cat')){
        document.querySelector('.current-cat').classList.add("active");
        if(document.querySelector('.current-cat ul.children')){
            document.querySelector('.current-cat ul.children').classList.add("show");
            document.querySelector('.current-cat ul.children').style.display = "block";
        }
    }

    if(document.querySelector('.current-cat-parent')){
        document.querySelector('.current-cat-parent').classList.add("active");
        if(document.querySelector('.current-cat-parent ul.children')){
            document.querySelector('.current-cat-parent ul.children').classList.add("show");
            document.querySelector('.current-cat-parent ul.children').style.display = "block";
        }
    }

    $parentMenu.on('click', '> a', function(e) {
        e.preventDefault();
        
        var $this = $(this);

        if ($this.next().hasClass('show')) {
            $this.next().slideUp(350);
            $this.next().removeClass('show');
        } else {
            $this.parent().parent().find('li .children').removeClass('show');
            $this.parent().parent().find('li .children').slideUp(350);
            $this.next().toggleClass('show');
            $this.next().slideToggle(350);
        }
    });
});
/* Behavior - behaviors/webfont-load.js */

$(function() {
    
    // Load Fonts
    WebFont.load({
        google: {
            families: ['Montserrat:400,400i,600,600i,700']
        }
    });
});
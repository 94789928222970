if ($('.home')[0]) {
} else {
    $(window).on('load', function() {
        if (window.location.pathname != '/' && window.innerWidth < 1024 && $(".home") ) {
            $('body, html').animate({
                scrollTop: $('main').offset().top - 20
            }, 600);
        }
    });
}

/* Behavior - behaviors/slide.js */

$(function () {
    var $slide = $('.box-slide .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 1,
        autoplay: true,
        loop: false,
        navContainer: $('.slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 8000
    });

    var $slideNavs = $('.slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.slide-navs').addClass('disabled');
});

$(function () {
    var $products = $('.featured-loop .owl-carousel');

    if(!$products.length) return

    $products.owlCarousel({
        items: 4,
        autoplay: true,
        loop: false,
        margin:30,
        navContainer: $('.featured-loop .products-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 6000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            576:{
                items:2,
                nav:false
            },
            992:{
                items:3,
                nav:true
            },
            1920:{
                items:4,
                nav:true
            },
        }
    });

    var $productsNavs = $('.featured-loop .products-navs-inner.disabled');
    
    if(!$productsNavs.length) return
    $('.featured-loop .products-navs').addClass('disabled');
});

$(function () {
    var $products = $('.first-loop .owl-carousel');

    if(!$products.length) return

    $products.owlCarousel({
        items: 4,
        autoplay: true,
        loop: false,
        margin:30,
        navContainer: $('.first-loop .products-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 6000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            576:{
                items:2,
                nav:false
            },
            992:{
                items:3,
                nav:true
            },
            1920:{
                items:4,
                nav:true
            },
        }
    });

    var $productsNavs = $('.first-loop .products-navs-inner.disabled');
    
    if(!$productsNavs.length) return
    $('.first-loop .products-navs').addClass('disabled');
});

$(function () {
    var $products = $('.second-loop .owl-carousel');

    if(!$products.length) return

    $products.owlCarousel({
        items: 4,
        autoplay: true,
        loop: false,
        margin:30,
        navContainer: $('.second-loop .products-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 6000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            576:{
                items:2,
                nav:false
            },
            992:{
                items:3,
                nav:true
            },
            1920:{
                items:4,
                nav:true
            },
        }
    });

    var $productsNavs = $('.second-loop .products-navs-inner.disabled');
    
    if(!$productsNavs.length) return
    $('.second-loop .products-navs').addClass('disabled');
});